import { OrganizationDetailsGeneral } from '../components/Organization/Details';

export function OrganizationGeneralRoute() {
  return <OrganizationDetailsGeneral />;
}

export const Component = OrganizationGeneralRoute;

export function clientLoader() {
  return null;
}
